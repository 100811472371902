<template>
  <div class="country-flag-wrapper">
    <img :src="`https://flagcdn.com/w80/${countryCode.toLowerCase()}.png`" alt=''/>
  </div>
</template>

<script>
import "./countryFlag.scss"
export default {
  props: ["countryCode"],
  components: {},
};
</script>

<style lang="scss" scoped></style>